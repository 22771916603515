import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "form","formbox","response" ]

  connect() {
    
  }

  closeResponse(){
  	this.responseTarget.innerHTML = ""
  	this.formboxTarget.classList.remove('hidden')
  	this.formTarget.reset()
  }
}
