import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["attendance", 
                    "billing", 
                    "customize",
                    "documents", 
                    "general", 
                    "rooms", 
                    "streams", 
                    "tabAttendance", 
                    "tabBilling", 
                    "tabCustomize",
                    "tabDocuments",
                    "tabGeneral", 
                    "tabRooms",
                    "tabStreams" ]

  connect() {
    this.page = this.generalTarget
    this.tab = this.tabGeneralTarget



    addEventListener("direct-upload:initialize", event => {
        const { target, detail } = event
        const { id, file } = detail
        target.insertAdjacentHTML("beforebegin", `
          <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
            <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
            <span class="direct-upload__filename"></span>
          </div>
        `)
        target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
      })

      addEventListener("direct-upload:start", event => {
        const { id } = event.detail
        const element = document.getElementById(`direct-upload-${id}`)
        element.classList.remove("direct-upload--pending")
      })

      addEventListener("direct-upload:progress", event => {
        const { id, progress } = event.detail
        const progressElement = document.getElementById(`direct-upload-progress-${id}`)
        progressElement.style.width = `${progress}%`
      })

      addEventListener("direct-upload:error", event => {
        event.preventDefault()
        const { id, error } = event.detail
        const element = document.getElementById(`direct-upload-${id}`)
        element.classList.add("direct-upload--error")
        element.setAttribute("title", error)
      })

      addEventListener("direct-upload:end", event => {
        const { id } = event.detail
        const element = document.getElementById(`direct-upload-${id}`)
        element.classList.add("direct-upload--complete")
      })
  }

  displayAttendance(e){
    this.displayTab(this.attendanceTarget, this.tabAttendanceTarget)
  }
  displayBilling(e){
    this.displayTab(this.billingTarget, this.tabBillingTarget)
  }
  displayCustomize(e){
    this.displayTab(this.customizeTarget, this.tabCustomizeTarget)
  }
  displayDocuments(e){
    this.displayTab(this.documentsTarget, this.tabDocumentsTarget)
  }
  displayGeneral(e){
    this.displayTab(this.generalTarget, this.tabGeneralTarget)
  }
  displayRooms(e){
    this.displayTab(this.roomsTarget, this.tabRoomsTarget)
  }
  displayStreams(e){
    this.displayTab(this.streamsTarget, this.tabStreamsTarget)
  }

  

  

  displayTab(target, tab) {
    this.page.classList.add('hidden')
    this.tab.classList.remove('tab_active')
    this.tab.classList.add('tab_normal')
    target.classList.remove('hidden')
    tab.classList.remove('tab_normal')
    tab.classList.add('tab_active')
    this.page = target
    this.tab = tab
  }

  selectChange(e){
    console.log(e.target.value)
    switch(e.target.value){
      case 'attendance':
        this.displayTab(this.attendanceTarget, this.tabAttendanceTarget)
        break;
      case 'billing':
        this.displayTab(this.billingTarget, this.tabBillingTarget)
        break;
      case 'customize':
        this.displayTab(this.customizeTarget, this.tabCustomizeTarget)
        break;
      case 'documents':
        this.displayTab(this.documentsTarget, this.tabDocumentsTarget)
        break;
      case 'general':
        this.displayTab(this.generalTarget, this.tabGeneralTarget)
        break;
      case 'rooms':
        this.displayTab(this.roomsTarget, this.tabRoomsTarget)
        break;
      case 'streams':
        this.displayTab(this.streamsTarget, this.tabStreamsTarget)
        break;
      }
  }


}