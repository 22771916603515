import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
	static targets = ["chatbuttontext", "chatbutton", "questionbuttontext", "questionbutton","room"]
	connect() {
		StimulusReflex.register(this)
	}
	initialize(){
		this.subscription()
	}

	disconnect(){
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
		let room = 'controll_room_'+ this.roomTarget.dataset.room
		 if (this._subscription == undefined) {
      		let _this = this
      		this._subscription = consumer.subscriptions.create({ channel: "RoomControllerChannel", room: room}, {
				connected() {
					//console.log('connected to RoomControllerChannel '+ room )
				},
				disconnected(){
					//console.log('disconnected to room '+ room )
				},
				received(data){
					switch(data.msg) {
						case 'enable_chat':
							_this.enableChat()
						break;
						case 'disable_chat':
							_this.disableChat()
						break;
						case 'enable_question':
							_this.enableQuestion()
						break;
						case 'disable_question':
							_this.disableQuestion()
						break;
						case 'new_arrival':
							_this.newArrival(data)
						break;
						case 'appear':
							_this.appear(data.id)
						break;
						case 'disappear':
							_this.disappear(data.id)
						break;
						case 'voting_right':
							_this.votingRight(data)
						break;
					}
				}
			})
		}
		return this._subscription
	}
	// SITE ACTIONS

	// Click Action to toggle the chat
	chat(){
		let status = this.chatbuttonTarget.getAttribute('status')
		if (status === 'true')
			this.stimulate('Togglers::ChatTogglerReflex#disable', this.roomTarget.dataset.room)
		else
			this.stimulate('Togglers::ChatTogglerReflex#enable', this.roomTarget.dataset.room)
	}
	// Click Action to toggle the question pane
	question(){
		let status = this.questionbuttonTarget.getAttribute('status')
		if (status === 'true')
			this.stimulate('Togglers::QuestionTogglerReflex#disable', this.roomTarget.dataset.room)
		else
			this.stimulate('Togglers::QuestionTogglerReflex#enable', this.roomTarget.dataset.room)
	}

	// FUNCTIONS TO BE CALLED ROM ACTIONCABLE

	// Funtion to put new arrivals to the rooms overview of attendance page
	newArrival(data){
		let container = document.getElementById('attendances')
		if( container != null) {
			container.insertAdjacentHTML('beforeend', data.user)
		}
	}

	// Function to mark user as in the room
	appear(id){
		let userid = "user_status_"+ id
		let user = document.getElementById(userid)
		if (user != null){
			user.classList.remove('bg-red-400')
			user.classList.add('bg-green-400')
		}
	}

	// Function to mark the user as not in the room
	disappear(id){
		let userid = "user_status_"+ id
		let user = document.getElementById(userid)
		if (user != null){
			user.classList.remove('bg-green-400')
			user.classList.add('bg-red-400')
		}
	}

	votingRight(data) {
		let att = document.getElementById("voting_right_"+data.id)
		if (att != null){
			if(data.status === false){
				att.classList.remove('btn_sm_red')
				att.classList.add('btn_sm_success_action')
				att.innerHTML = att.dataset.give
			}else{
				att.classList.remove('btn_sm_success_action')
				att.classList.add('btn_sm_red')
				att.innerHTML = att.dataset.take
			}
		}
	}

	// function to change the button state
	enableChat(){		
		this.chatbuttonTarget.setAttribute('status', 'true')
		this.chatbuttonTarget.classList.remove('btn_green')
		this.chatbuttonTarget.classList.add('btn_red')
		this.chatbuttontextTarget.innerHTML = this.chatbuttonTarget.dataset.disabletext
	}
	// function to change the button state
	disableChat(){
		this.chatbuttonTarget.setAttribute('status', 'false')
		this.chatbuttonTarget.classList.remove('btn_red')
		this.chatbuttonTarget.classList.add('btn_green')
		this.chatbuttontextTarget.innerHTML = this.chatbuttonTarget.dataset.enabletext
	}
	// function to change the button state
	enableQuestion(){		
		this.questionbuttonTarget.setAttribute('status', 'true')
		this.questionbuttonTarget.classList.remove('btn_green')
		this.questionbuttonTarget.classList.add('btn_red')
		this.questionbuttontextTarget.innerHTML = this.questionbuttonTarget.dataset.disabletext
	}
	// function to change the button state
	disableQuestion(){
		this.questionbuttonTarget.setAttribute('status', 'false')
		this.questionbuttonTarget.classList.remove('btn_red')
		this.questionbuttonTarget.classList.add('btn_green')
		this.questionbuttontextTarget.innerHTML = this.questionbuttonTarget.dataset.enabletext
	}


}
