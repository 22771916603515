import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
	static targets = ["room"]
	connect() {
		StimulusReflex.register(this)
	}
	initialize() {
		this.subscription()
	}

	disconnect() {
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
		let room = this.roomTarget.dataset.room
		if (this._subscription == undefined) {
			let _this = this
			this._subscription = consumer.subscriptions.create({ channel: "QuestionChannel", room_slug: room }, {
				connected() {
					console.log('connected to old moderator channel' + room)
				},
				disconnected() {

				},
				received(data) {
					console.log('INCOMMING')
					if (data.msg === "moderator")
						_this.newQuestion(data.question);
					else if (data.msg === "deleted")
						_this.deleted(data.id)
					else if (data.msg === "answered")
						_this.deleted(data.id)
					else if (data.msg === "dismissed")
						_this.deleted(data.id)
					else if (data.msg === "show_question")
						_this.showActivePoll(data.poll)
					else if (data.msg === "hide_question")
						_this.hideActivePoll(data.id)
					else if (data.msg === "show_poll_results")
						_this.showResultPoll(data)
					else if (data.msg === "hide_poll_results")
						_this.hideResultPoll(data.id)
				}
			})
		}
		return this._subscription
	}
	newQuestion(question) {
		this.roomTarget.insertAdjacentHTML('afterbegin', question)
	}

	showResultPoll(data) {
		this.hideActivePoll(data.id)
		this.roomTarget.insertAdjacentHTML('afterbegin', data.poll)
	}
	hideResultPoll(id) {
		let q = document.getElementById('poll_result_' + id)
		if (q != null)
			q.parentNode.removeChild(q)
	}

	showResultVote(data) {
		this.roomTarget.insertAdjacentHTML('afterbegin', data.vote)
	}
	hideResultVote(id) {
		let q = document.getElementById('vote_result_' + id)
		if (q != null)
			q.parentNode.removeChild(q)
	}

	showActivePoll(poll) {
		this.roomTarget.insertAdjacentHTML('afterbegin', poll)
	}
	hideActivePoll(id) {
		let q = document.getElementById('poll_' + id)
		if (q != null)
			q.parentNode.removeChild(q)
	}

	deleted(id) {
		let q = document.getElementById(id)
		if (q != null)
			q.parentNode.removeChild(q)
	}

}
