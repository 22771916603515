import { Controller } from "stimulus"

export default class extends Controller {


  connect() {
    let element = document.getElementById('checkin_registration')
    let code = element.dataset.id
    let script = "<script>(function(w, d) {d.event_id = "+code+";var headTag = d.getElementsByTagName('head')[0];var script = d.createElement('script');script.src = 'https://registration.checkin.no/registration.loader.js';script.async = 1;script.crossOrigin = 1;headTag.appendChild(script);})(window, document);</script>"
    element.insertAdjacentHTML('afterEnd', script)
  }
}
