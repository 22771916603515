import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [ "input", "submit", "output" ]

  connect() {
	const input = this.inputTarget

	// Bind to normal file selection
	input.addEventListener('change', (event) => {
		console.log(event)
		if(this.inputTarget.value != null ){
			this.submitTarget.classList.remove('hidden')
		}else {
			this.submitTarget.classList.add('hidden')
		}
		this.outputTarget.innerHTML = ""
	  Array.from(input.files).forEach(file => {
			this.outputTarget.insertAdjacentHTML('beforeend', '<div class="file_name_list">'+file.name+'</div>')
	  		}
	  	)
	  
	})


	addEventListener("direct-upload:initialize", event => {
	  const { target, detail } = event
	  const { id, file } = detail
	  this.outputTarget.insertAdjacentHTML("beforebegin", `
	  	<div class="w-full ">
	  	 <div id="direct-upload-${id}" class="shadow w-full bg-yellow-300 mt-2 text-center">
		    <div id="direct-upload-progress-${id}" class="h-4  bg-green-500 text-xs leading-none py-1 text-center text-gray-700" style="width: 0%">
		    	<span class="w-100">
		    		${file.name}
		    	</span>
		    </div>
		  </div> 
		 </div>`)
	  //this.outputTarget.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
	})

	addEventListener("direct-upload:start", event => {
	  const { id } = event.detail
	  const element = document.getElementById(`direct-upload-${id}`)
	  element.classList.add("bg-gray-100")
	  element.classList.remove("bg-yellow-300")
	})

	addEventListener("direct-upload:progress", event => {
	  const { id, progress } = event.detail
	  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
	  progressElement.style.width = `${progress}%`
	})

	addEventListener("direct-upload:error", event => {
	  event.preventDefault()
	  const { id, error } = event.detail
	  const element = document.getElementById(`direct-upload-${id}`)
	  element.classList.add("direct-upload--error")
	  element.setAttribute("title", error)
	})

	addEventListener("direct-upload:end", event => {
	  const { id } = event.detail
	  const element = document.getElementById(`direct-upload-${id}`)
	  element.classList.add("direct-upload--complete")
	})

  }

  clearForm(){
  	input.value = null
  }

  clearFiles(){
  	this.outputTarget.innerHTML = ""
  }

}
