import { Controller } from 'stimulus'
import { Sortable } from '@shopify/draggable'
import { Plugins } from '@shopify/draggable';
import SwapAnimation from '@shopify/draggable/lib/plugins/swap-animation';
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  static targets = ['day', 'item']
  initialize() {}
  connect() {
    StimulusReflex.register(this)
    let _this = this
    let lastTime = 0
    if (this.hasItemTarget) {
      this.itemTargets.forEach(item => {
        item.setAttribute('style', 'z-index: 1000;')
      })
      const sortable = new Sortable(this.dayTargets, {
        draggable: 'tr',
        swapAnimation: {
          duration: 400,
          easingFunction: 'ease-in-out',
          vertical: true
        },
        plugins: [Plugins.SwapAnimation]
      })
      sortable.on('sortable:stop', function(event) {
        let array = Array.from(event.newContainer.children)
        array.forEach((item, index) => {
          if (item.classList.contains('draggable--original')) {
            array.splice(index, 1)
          } else if (item.classList.contains('draggable-mirror')) {
            array.splice(index, 1)
          }
        })
        let roomid = _this.dayTarget.dataset.id
        let positions = array.map(item => item.dataset.id)
        let new_order = event.newIndex
        let data = { program_item: { positions: positions }  }
        _this.stimulate('Rooms::ProgramReflex#update_position', positions, roomid, positions[0])
       
      })
    }
  }

  calculateTime() {

  }

  disconnect() {}
}