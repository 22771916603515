import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"

export default class extends Controller {
	static targets = ["room"]
	initialize() {
		this.subscription()
	}

	disconnect() {
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
		let room = this.roomTarget.dataset.room
		if (this._subscription == undefined) {
			let _this = this
			this._subscription = consumer.subscriptions.create({ channel: "RoomChannel", room: room }, {
				connected() {
					//console.log('connected to RoomChannel '+ room )
				},
				disconnected() {

					//console.log('disconnected to RoomChannel '+ room )
				},
				received(data) {
					if (data.cableReady) CableReady.perform(data.operations)
				}
			})
		}
		return this._subscription
	}
}
