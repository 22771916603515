import { Controller } from 'stimulus'
import { Sortable } from '@shopify/draggable'
import { Plugins } from '@shopify/draggable';
import SwapAnimation from '@shopify/draggable/lib/plugins/swap-animation';
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  static targets = ['list', 'speaker']
  initialize() {}
  connect() {
    console.log('Yo bro from add title')
    StimulusReflex.register(this)
    let _this = this
    let lastTime = 0
    if (this.hasSpeakerTarget) {
      this.speakerTargets.forEach(speaker => {
        speaker.setAttribute('style', 'z-index: 1000;')
      })
      const sortable = new Sortable(this.listTargets, {
        draggable: 'li',
        swapAnimation: {
          duration: 400,
          easingFunction: 'ease-in-out',
          vertical: true
        },
        plugins: [Plugins.SwapAnimation]
      })
      sortable.on('sortable:stop', function(event) {
        let array = Array.from(event.newContainer.children)
        array.forEach((speaker, index) => {
          if (speaker.classList.contains('draggable--original')) {
            array.splice(index, 1)
          } else if (speaker.classList.contains('draggable-mirror')) {
            array.splice(index, 1)
          }
        })
        let program_item_id = _this.listTarget.dataset.id
        let positions = array.map(speaker => speaker.dataset.id)
        let new_order = event.newIndex
        let data = { program_speakers: { positions: positions }  }
        console.log(positions, program_item_id)
        //_this.perform('update_positions', {data,})
        _this.stimulate('Rooms::ProgramReflex#update_speaker_position', positions, program_item_id)
      })
    }
  }

  calculateTime() {

  }

  disconnect() {}
}
