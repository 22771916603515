import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import Swal from 'sweetalert2/dist/sweetalert2.js'
export default class extends Controller {
	static targets = ["chatbuttontext", "chatbutton", "questionbuttontext", "questionbutton","room", "votebox"]
	connect() {

		
	}
	initialize(){
		this.subscription()
	}

	disconnect(){
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
		let room = this.roomTarget.dataset.room
		 if (this._subscription == undefined) {
      		let _this = this
      		this._subscription = consumer.subscriptions.create({ channel: "ToggleChannel", room_slug: room}, {
				connected() {
				},
				disconnected(){
					//console.log('disconnected to room '+ room )
				},
				received(data){
					if(data.msg === "enable_chat")
						_this.enableChat(data)
					else if(data.msg === "disable_chat")
						_this.disableChat()
					else if(data.msg === "enable_question")
						_this.enableQuestion(data)
					else if(data.msg === "disable_question")
						_this.disableQuestion()
					else if(data.msg === "vote_started")
						_this.voteStarted(data)
					else if(data.msg === "vote_ended")
						_this.voteEnded(data)
					else if(data.msg === "vote_new")
						_this.voteCounter(data)
					else if(data.msg === "show_vote_results")
						_this.showVoteResults(data)
					else if(data.msg === "hide_vote_results")
						_this.hideVoteResults(data)
				}
			})
		}
		return this._subscription
	}


	enableChat(data){		
		let chatbutton = document.getElementById('chat_button')
		chatbutton.insertAdjacentHTML('afterbegin', data.chatbutton )
		let chatbox = document.getElementById('chat_box')
		chatbox.classList.remove('hidden')
	}

	disableChat(){
		let chatbutton = document.getElementById('chat_button')
		chatbutton.innerHTML = ""
		let chatbox = document.getElementById('chat_box')
		chatbox.classList.add('hidden')
	}
	enableQuestion(data){		
		let questionContainer = document.getElementById('question_box')
		questionContainer.innerHTML = ""
		questionContainer.insertAdjacentHTML('afterbegin', data.question_box)
	}

	disableQuestion(){
		let q = document.getElementById('question_conatainer')

		q.classList.add('fadeOut')
		setTimeout(function(){
			q.parentNode.removeChild(q)
			}, 1000)
	}

	voteStarted(data){
		this.voteboxTarget.insertAdjacentHTML('afterbegin', data.vote)
	}

	voteEnded(data){
		let v = document.getElementById('vote_'+data.id)

		v.classList.add('fadeOut')
		setTimeout(function(){
			v.parentNode.removeChild(v)
			}, 1000)
	}

	showVoteResults(data){
		this.voteboxTarget.insertAdjacentHTML('afterbegin', data.vote)
	}

	hideVoteResults(data){
		let v = document.getElementById('vote_result_'+data.id)

		if (v != null) {
			v.classList.add('fadeOut')
		setTimeout(function(){
			v.parentNode.removeChild(v)
			}, 1000)
	}
	}

	voteCounter(data){
		let counter = document.getElementById('vote_count_'+data.id)
		counter.innerHTML = data.count
	}


}
