import { Controller } from "stimulus"


export default class extends Controller {
	static targets = ["biobutton"]
	connect() {
	}

	getBio(e) {
		let _this = this
		this.showBio();
		let url = e.srcElement.dataset.url
		let that = this
		fetch(url, { method: 'GET' }).then(response => response.text())
			.then(response => {
				_this.populateBio(response)

			})

	}
	showBio(data) {
		if (document.getElementById('bio').classList.contains('hidden')) {
			document.getElementById('bio').classList.remove('hidden')
			document.getElementById('bio_spinner').innerHTML = '<i class="fas fa-4x fa-spinner text-gray-500 fa-spin"></i>'
		}
	}

	closeBio() {
		document.getElementById('bio').classList.add('hidden')
		document.getElementById('speaker_bio_some').innerHTML = ""
		document.getElementById('speaker_bio_name').innerHTML = ""
		document.getElementById('speaker_bio_title').innerHTML = ""
		document.getElementById('speaker_bio_bio').innerHTML = ""
		document.getElementById('speaker_bio_image').src = ""
		document.getElementById('speaker_bio_image').classList.add('hidden')
	}

	populateBio(data) {
		let speaker = JSON.parse(data)
		document.getElementById('speaker_bio_some').innerHTML = ""
		document.getElementById('speaker_bio_name').innerHTML = speaker.name
		document.getElementById('speaker_bio_title').innerHTML = speaker.title
		document.getElementById('speaker_bio_bio').innerHTML = speaker.bio
		document.getElementById('speaker_bio_image').src = speaker.image
		if (speaker.instagram.length > 4) {
			this.appendSome('fab fa-instagram', speaker.instagram)
		}
		if (speaker.twitter.length > 4) {
			this.appendSome('fab fa-twitter', speaker.twitter)
		}
		if (speaker.linkedin.length > 4) {
			this.appendSome('fab fa-linkedin', speaker.linkedin)
		}
		if (speaker.email.length > 4) {
			this.appendSome('fas fa-at', speaker.email)
		}



		document.getElementById('bio_spinner').innerHTML = ""
		document.getElementById('speaker_bio_image').classList.remove('hidden')
	}

	appendSome(icon, handle) {
		document.getElementById('speaker_bio_some').insertAdjacentHTML('beforeend', '<p><i class="' + icon + ' mr-2"></i>' + handle + '</p>')
	}

}