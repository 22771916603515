import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
	static targets = ["room"]
	connect() {
		StimulusReflex.register(this)
	}
	initialize(){
		this.subscription()
	}

	disconnect(){
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
		let room = this.roomTarget.dataset.room
		 if (this._subscription == undefined) {
      		let _this = this
      		this._subscription = consumer.subscriptions.create({ channel: "QuestionChannel", room_slug: room}, {
				connected() {
					//console.log('connected to room '+ room )
				},
				disconnected(){
					//console.log('disconnected to room '+ room )
				},
				received(data){
					
					if(data.msg === "new")
						_this.newQuestion(data.question);
					else if(data.msg === "answered")
						_this.answeredQuestion(data.id);
					else if(data.msg === "dismissed")
						_this.dismissedQuestion(data.id);
					else if(data.msg === "deleted")
						_this.deletedQuestion(data.id);
					else if(data.msg === "moderator")
						_this.sentModerator(data.id)
				}
			})
		}
		return this._subscription
	}
	newQuestion(question){
		this.roomTarget.insertAdjacentHTML('afterbegin', question)
	}
	answeredQuestion(id){
		let q = document.getElementById(id)
		q.classList.remove('bg-white')
		q.classList.add('bg-green-200')
	}
	dismissedQuestion(id){
		let q = document.getElementById(id)
		q.classList.remove('bg-white')
		q.classList.add('bg-yellow-200')
		let button = document.getElementById('moderator_'+id)
		button.parentNode.removeChild(button)
	}

	deletedQuestion(id){
		let q = document.getElementById(id)
		q.parentNode.removeChild(q)
	}
	answered(e){
		this.stimulate('QuestionReflex#answered', e.srcElement.dataset.id)
	}
	delete(e){
		console.log('deleting...')
		this.stimulate('QuestionReflex#delete', e.srcElement.dataset.id)
	}

	moderator(e){
		this.stimulate('QuestionReflex#moderator', e.srcElement.dataset.id)
	}

	sentModerator(id){
		let b = document.getElementById('moderator_'+id)
		b.classList.add('hidden')
	}
}
