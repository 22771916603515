import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["biobutton"]
  connect() {
  }

  getRecording(e) {
    let _this = this
    let url = e.srcElement.dataset.url
    fetch(url, { method: 'GET' }).then(response => response.text())
      .then(response => {
        _this.showRecording(response)
        console.log(response)
      })

  }

  showRecording(data) {
    let stream = JSON.parse(data)
    let stream_container = document.getElementById('stream_container')
    stream_container.innerHTML = stream.embed_code
  }

}
