import { Controller } from "stimulus"
let fetched = false
export default class extends Controller {
	static targets = ["output"]

	connect() {
		let _this = this

		if (!fetched) {
			_this.fetchSpeakers()
			fetched = true
		}
	}

	fetchSpeakers() {
		let url = this.outputTarget.dataset.url
		let _this = this
		fetch(url, { method: 'GET' }).then(response => response.text())
			.then(response => {
				_this.populatespeakers(response)
			})
	}

	populatespeakers(response) {
		let json = JSON.parse(response)
		json.speakers.forEach((speaker) => {
			this.outputTarget.insertAdjacentHTML('beforeend', '<div class="animated fadeIn flex flex-col shadow-lg overflow-hidden"><a id="speaker_' + speaker.id + '"><div class="flex-shrink-0"><img src="' + speaker.image + '" class="h-48 w-full object-cover" /></div><div class="flex-1 bg-white p-6 flex flex-col justify-between"><div class="flex-1"><h3 class="mt-2 text-xl leading-7 font-semibold text-blue-900">' + speaker.name + '</h3><p class="text-sm leading-5 font-medium text-indigo-600"><span class="text-xs text-gray-500">' + speaker.title + '</span></p></div></div></a></div>')
		})
	}


}
