import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'


export default class extends Controller {

  static targets = ['input']

  connect(){
    StimulusReflex.register(this)
  }

  submitForm(e) {
    if (e.key === 'Enter'){
      this.stimulate('PollOptionReflex#add')
    }
  }
  submitOptionForm(e) {
    if (e.key === 'Enter'){
      this.stimulate('PollOptionReflex#update')
    }
  }
}
