import { Controller } from "stimulus"
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ["enddate", "enddatefield" , "mulitpledays", "startdate"]

  connect() {
  	flatpickr("[data-behavior='flatpickr']", {
  		altInput: true,
  		altFormat: "F j, Y",
  		dateFormat: 'd-M-Y',
  		defaultDate: Date.now(),
  		minDate: Date.now(),
  	})
  }

  multipleDays(){
    this.mulitpledaysTarget.checked ? this.displayEndDate() : this.hideEndDate()
  }

  displayEndDate() {
    this.enddatefieldTarget.classList.remove('hidden')
  }

  hideEndDate() {
    this.enddatefieldTarget.classList.add('hidden')
  }


  startDateChanged(){
    console.log(this.enddateTarget.value)
    this.enddateTarget.setAttribute('min', this.startdateTarget.value)
    if(this.enddateTarget.value === ""){
      this.enddateTarget.value = this.startdateTarget.value
    }
  }
  endDateChanged(){

  }

}