import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'


export default class extends Controller {
  static targets = ['iniviteButton', 'status', 'button', 'buttonText', 'body', 'title']
  connect () {
    StimulusReflex.register(this)
  }

  beforeReflex() {
    let _this = this
    let text = this.element.dataset.saved
    this.statusTarget.innerHTML = '<i class="fa fa-check mr-2 text-green-500"></i>' + text
      setTimeout(function(){
						_this.statusTarget.innerHTML = ''
					}, 1500)
  }

  toggleButtonText() {
      this.iniviteButtonTarget.classList.toggle('hidden')
      this.buttonTarget.classList.toggle('hidden')
  }

  updateBody(event){
      this.bodyTarget.innerHTML = event.srcElement.value
  }

  updateButtonText(event) {
      this.buttonTextTarget.innerHTML = event.srcElement.value
  }

  updateTitle(event){
      this.titleTarget.innerHTML = event.srcElement.value
  }



}