import consumer from "../../channels/consumer"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "uchannel"]

	connect() {
		let that = this
		let room = 'notifications_' +this.uchannelTarget.dataset.uchannel
		consumer.subscriptions.create({ 
			channel: "NotificationsChannel", 
			room: room }, 
			{
				connected() {},
				received(data) {
					// Called when there's incoming data on 
					// the websocket for this channel
					// Sets the notification on the page
					that.uchannelTarget.insertAdjacentHTML('afterbegin', data.notification)
					// Sets the fadeout animation
					setTimeout(function(){
						let notification = document.getElementById('notification_'+data.id)
						notification.classList.add('fadeOutUp')
					}, 8000)
					// Removes the notification from the ui
					setTimeout(function(){
						console.log(data.id)
						let notification = document.getElementById('notification_'+data.id)
						if(notification != null){
							notification.parentNode.removeChild(notification)
						}
					}, 9000)
				}
			}
		)
	}
}
