import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "email", "addbutton"]
 	connect() {}

  	typeEmail(){
  	this.addbuttonTarget.dataset.email = this.emailTarget.value
  	}
  	isUpdating(){

  	}

}
  