import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"
import { Sortable } from '@shopify/draggable'
import { Plugins } from '@shopify/draggable';
import SwapAnimation from '@shopify/draggable/lib/plugins/swap-animation';

export default class extends Controller {
	static targets = ['list', 'sponsor']
	initialize(){
		this.subscription()
	}

	disconnect(){
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
	    let room = 'event_editor_' + this.element.dataset.room
		 if (this._subscription == undefined) {
      		let _this = this
      		this._subscription = consumer.subscriptions.create({ channel: "EventEditorChannel", room: room}, {
				connected() {
                },
				disconnected(){},
				received(data){
					if (data.cableReady) CableReady.perform(data.operations)
				}
			})
		}
		return this._subscription
	}

	connect() {
		this.sortSponsors()
	  }


	sortSponsors(){
        let _this = this
        let lastTime = 0
        if (this.hasSponsorTarget) {
          this.sponsorTargets.forEach(sponsor => {
            sponsor.setAttribute('style', 'z-index: 1000;')
          })
          const sortable = new Sortable(this.listTargets, {
            draggable: 'li',
            swapAnimation: {
              duration: 400,
              easingFunction: 'ease-in-out',
              vertical: true
            },
            plugins: [Plugins.SwapAnimation]
          })
          sortable.on('sortable:stop', function(event) {
            let array = Array.from(event.newContainer.children)
            array.forEach((sponsor, index) => {
              if (sponsor.classList.contains('draggable--original')) {
                array.splice(index, 1)
              } else if (sponsor.classList.contains('draggable-mirror')) {
                array.splice(index, 1)
              }
            })
            let positions = array.map(sponsor => sponsor.dataset.id)
			let data = { positions: positions }
			_this.subscription().perform('sort_sponsors', {data})
		  })
		}
	}

}

