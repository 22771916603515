import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu", "profile" ]

  connect() {}

  toggleMobileMenu(){
	this.menuTarget.dataset.isopen === 'false' ? this.openMenu() : this.closeMenu()
  }

  toggleProfile(){
    this.profileTarget.dataset.isopen === 'false' ? this.openProfile() : this.closeProfile()
  }

  openMenu(){
  	this.menuTarget.classList.add('block')
  	this.menuTarget.classList.remove('hidden')
  	this.menuTarget.dataset.isopen = 'true'
  }

  closeMenu(){
  	this.menuTarget.classList.remove('block')
  	this.menuTarget.classList.add('hidden')
  	this.menuTarget.dataset.isopen = 'false'
  }

  openProfile(){
    this.profileTarget.classList.remove('hidden')
    this.profileTarget.dataset.isopen = 'true'
  }

  closeProfile(){
    this.profileTarget.classList.add('hidden')
    this.profileTarget.dataset.isopen = 'false'
  }
}
