import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"

export default class extends Controller {
	initialize(){
		this.subscription()
	}

	disconnect(){
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
		 if (this._subscription == undefined) {
      		let _this = this
      		this._subscription = consumer.subscriptions.create("AccountChannel", {
				connected() {},
				disconnected(){},
				received(data){
					if (data.cableReady) CableReady.perform(data.operations)
				}
			})
		}
		return this._subscription
	}

}
