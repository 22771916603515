import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "filefield", "logobutton" , "logoimage"]

  connect() {}
  updateLogo() {
  	let filename = this.filefieldTarget.value.split('\\')
  	this.logobuttonTarget.innerHTML = filename.pop()
  }
}