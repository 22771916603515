import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import StimulusReflex from 'stimulus_reflex';
import { Sortable } from '@shopify/draggable'
import { Plugins } from '@shopify/draggable';

export default class extends Controller {
	static targets = ["room", "percent", 'list', 'item']
	connect() {
		StimulusReflex.register(this)
		this.sortVoteItems()
	}
	initialize() {
		this.subscription()
	}

	disconnect() {
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
		let room = 'voting_backend_' + this.roomTarget.dataset.room
		if (this._subscription == undefined) {
			let _this = this
			this._subscription = consumer.subscriptions.create({ channel: "VotingChannel", room: room }, {
				connected() {
					console.log('connected to VotingChannel ' + room)
				},
				disconnected() {

				},
				received(data) {
					if (data.msg === "vote_started")
						_this.voteStart(data)
					if (data.msg === "vote_ended")
						_this.voteStart(data)
					else if (data.msg === "vote_new")
						_this.newVote(data)

				}
			})
		}
		return this._subscription
	}

	voteStart(data) {
		console.log('voteStarted')
	}
	voteEnded(data) {
		console.log('vite ended')
	}

	newVote(data) {
		console.log(data)
		let option = document.getElementById('option_' + data.option)
		option.innerHTML = data.count
		let percent = document.getElementById('percent_' + data.option)
		percent.dataset.total = data.count
		this.percentTargets.forEach((t) => {
			let percent = ((parseInt(t.dataset.total) / data.total) * 100).toFixed(2)
			t.innerHTML = percent
		})

	}

	sortVoteItems() {
		let _this = this
		if (this.hasItemTarget) {
			this.itemTargets.forEach(item => {
				item.setAttribute('style', 'z-index: 1000;')
			})
			const sortable = new Sortable(this.listTargets, {
				draggable: 'li',
				swapAnimation: {
					duration: 400,
					easingFunction: 'ease-in-out',
					vertical: true
				},
				plugins: [Plugins.SwapAnimation]
			})
			sortable.on('sortable:stop', function (event) {
				let array = Array.from(event.newContainer.children)
				array.forEach((item, index) => {
					if (item.classList.contains('draggable--original')) {
						array.splice(index, 1)
					} else if (item.classList.contains('draggable-mirror')) {
						array.splice(index, 1)
					}
				})
				let roomid = _this.listTarget.dataset.id
				let positions = array.map(item => item.dataset.id)
				let data = { positions: positions, id: roomid }
				_this.subscription().perform('votes_positions', { data })

			})
		}
	}


}
