import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "program" ]

  connect() {
  }

  toggleProgram(e){
  	let id = e.srcElement.dataset.id
  	let _this = this
  	let id_string = 'program_content_' + id
  	let content = document.getElementById(id_string)
  	let url = '/api/v1/program_items/'+id
  	content.classList.toggle('hidden')
  	if (!content.classList.contains('hidden')){
  		let spinner = document.getElementById('program_spinner_'+id)
	  	if (spinner != null) {
	  		spinner.innerHTML = '<div class="loader">Loading...</div>'
	  	}
		fetch(url, { method: 'GET'}).then(response => response.text())
		      	.then(response => {
		      		_this.addProgramItem(response, id)
		        	
		      	})
	 }
  }

  	addProgramItem(data, id) {
  		let json = JSON.parse(data)
  		let element = document.getElementById('program_content_'+id)
  		element.innerHTML = ""
  		if(json.description != null) {
        element.insertAdjacentHTML('afterbegin', '<span class="font-base">'+json.description+'</span><br/>' )
      }

  		//document.getElementById('program_spinner_'+json.id).innerHTML = ''
  		json.speakers.forEach((speaker) => {
  			let html = ""
  			let title = ""
  			
  			if(speaker.title != null){
  				title = '<span class="font-bold">'+speaker.title+'</span><br />'
  			}
  			element.insertAdjacentHTML('beforeend', '<p><div clas="mt-3">'+title+'<span class="text-base text-blue-900 cursor-pointer"> <a href="#speaker_'+speaker.speaker_id+'" target="_self">'+ speaker.name+' </a></span><br /> '+speaker.speaker_title+'</div></p>')
  			document.querySelectorAll('a[href^="#"]').forEach(anchor => {
   			 anchor.addEventListener('click', function (e) {
        		e.preventDefault();

			        document.querySelector(this.getAttribute('href')).scrollIntoView({
			            behavior: 'smooth',
			            block: 'center'
			        });
			    });
			});




  		})
  	}


}
