import { Controller } from "stimulus"
import consumer from '../../channels/consumer'

export default class extends Controller {
	static targets = ["speakers"]

	connect() {

	}
	initialize(){
		this.subscription()
	}

	disconnect(){
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}


	subscription() {
		let room = "speakers_"+this.speakersTarget.dataset.room
		 if (this._subscription == undefined) {
      		let _this = this
      		this._subscription = consumer.subscriptions.create({ channel: "ToggleSpeakersChannel", room_slug: room}, {
				connected() {
					
				},
				disconnected(){

				},
				received(data){
					if(data.msg === "push")
						_this.enterSpeaker(data);
					else if(data.msg === "pull")
						_this.removeSpeaker(data.id);
				}
			})
		}
		return this._subscription
	}


	enterSpeaker(data){
		let url = '/api/v1/speakers/bio/'+data.id
		let _this = this
	    fetch(url, { method: 'GET'}).then(response => response.text())
      	.then(response => {
      		let json = JSON.parse(response)	
        	let speaker = '<div id="speaker_'+data.id+'" class="speaker_entrance animated fadeInRight" data-controller="room--speaker"><div class="grid grid-cols-6"><div class="col-span-2 px-4 py-5"><img src="'+json.image+'" class="ml-4 h-12 w-12 rounded-full " /><div class="speaker_bio_link" data-taget="room--speaker.biobutton" data-speakerid="'+data.id+'" data-action="click->room--speaker#getBio" data-url="'+url+'">'+json.bio_link+'</div></div><div class="col-span-4"><div class="py-5"><h3 class="text-lg leading-6 font-medium text-gray-900">'+json.name+'</h3><div class="mt-2 max-w-xl text-sm leading-snug text-gray-500"><p class="text-xs mb-2">'+json.title+'</p></div></div></div></div></div>'
      		_this.pushSpeaker(speaker)
      	})
	}

	pushSpeaker(speaker){
		this.speakersTarget.insertAdjacentHTML('afterbegin', speaker)
	}



	removeSpeaker(id){

		let speaker = document.getElementById('speaker_'+id)
		if (speaker != null ){
			speaker.classList.add('fadeOut')
			setTimeout(function(){
				speaker.parentNode.removeChild(speaker)
				}, 1000)}
		
	}
}
