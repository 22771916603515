import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import consumer from "../../channels/consumer"


export default class extends Controller {
  static targets = [ "box","form", "messages", "message", "room", "submit" ]

  connect() {
  	this.boxTarget.scrollTop = this.boxTarget.scrollHeight;
  	let that = this
  	let room = "chat_view:"+this.roomTarget.dataset.room
    consumer.subscriptions.create( { channel: "ChatChannel", room: room }, {  	
	  connected() {},
	  received(data) {
	    // Called when there's incoming data on the websocket for this channel
	    that.messagesTarget.insertAdjacentHTML('beforeend', data.message)
	    that.boxTarget.scrollTop = that.boxTarget.scrollHeight;

	    }
	})
	  }

	keyPress(e){
		if(e.key === "Enter" ){
			Rails.fire(this.formTarget, 'submit')
			//this.postMessage()
			e.preventDefault()
			this.formTarget.reset()
		} 
	}

	submitForm(e){
		Rails.fire(this.formTarget, 'submit')
			//this.postMessage()
			e.preventDefault()
			this.formTarget.reset()
			this.messageTarget.value = ""
	}

}
