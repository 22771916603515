import { Controller } from "stimulus"
import consumer from '../../channels/consumer'

export default class extends Controller {
	static targets = ["video"]

	connect() {
		let cover_photo = this.videoTarget.dataset.image
		this.videoTarget.style.backgroundImage = "url('"+ cover_photo+"')";
	}
	initialize(){
		this.subscription()
	}

	disconnect(){
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
		let room = this.videoTarget.dataset.room
		 if (this._subscription == undefined) {
      		let _this = this
      		this._subscription = consumer.subscriptions.create({ channel: "ToggeleStreamChannel", room_slug: room}, {
				connected() {

				},
				disconnected(){

				},
				received(data){
					if(data.msg === "start")
						_this.startStream(data.video);
					else if(data.msg === "stop")
						_this.stopStream();
				}
			})
		}
		return this._subscription
	}

	startStream(stream){
		this.videoTarget.innerHTML = stream
	}

	stopStream(){
		this.videoTarget.innerHTML = ""
	}
}
