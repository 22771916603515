import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {

  connect() {
  	StimulusReflex.register(this)
  }

  toggleRight(e){
  	console.log('Toggle_right clicked')
  	this.stimulate('Users::AttendanceReflex#toggle_right', e.srcElement.dataset.id)
  }

}
