import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["program", 'translation']

	connect() {
	}

	toggleProgram(e) {
		let id = e.srcElement.dataset.id
		let _this = this
		let id_string = 'program_content_room_' + id
		let content = document.getElementById(id_string)
		let url = '/api/v1/program_items/' + id
		content.classList.toggle('hidden')

		if (!content.classList.contains('hidden')) {
			let spinner = document.getElementById('program_spinner_' + id)
			if (spinner != null) {
				spinner.innerHTML = '<div class="loader">Loading...</div>'
			}
			fetch(url, { method: 'GET' }).then(response => response.text())
				.then(response => {
					_this.addProgramItem(response, id)

				})
		}
	}

	addProgramItem(data, id) {
		let _this = this
		let json = JSON.parse(data)
		let element = document.getElementById('program_content_room_' + id)
		element.innerHTML = ""
		if (json.description != null) {
			element.insertAdjacentHTML('afterbegin', '<span class="font-base">' + json.description + '</span><br/>')
		}

		if (json.stream > 0) {
			let event = _this.translationTarget.dataset.event
			let recording_url = "/api/v1/recording/" + event + "/" + json.stream
			element.insertAdjacentHTML('afterbegin', '<span class="font-base text-blue-800" data-controller="room--recording" data-action="click->room--recording#getRecording" data-target="room--recording.url" data-url="' + recording_url + '"> <i class="fas fa-film mr-3"></i>' + this.translationTarget.dataset.recording + '<span>')
		}
		//document.getElementById('program_spinner_'+json.id).innerHTML = ''
		json.speakers.forEach((speaker) => {
			let html = ""
			let title = ""
			let url = '/api/v1/speakers/bio/' + speaker.speaker_id

			if (speaker.title != null) {
				title = '<span class="font-bold">' + speaker.title + '</span><br />'
			}

			element.insertAdjacentHTML('beforeend', '<p class="animated fadeIn"><div clas="mt-3">' + title + '<span class="text-base text-blue-900 cursor-pointer" data-speakerid="' + speaker.speaker_id + '" data-action="click->room--speaker#getBio" data-url="' + url + '">' + speaker.name + '</span><br /> ' + speaker.speaker_title + '</div></p>')

		})
	}



}
