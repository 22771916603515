import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"
import { Sortable } from '@shopify/draggable'
import { Plugins } from '@shopify/draggable';
import SwapAnimation from '@shopify/draggable/lib/plugins/swap-animation';

export default class extends Controller {
	static targets = ['day', 'list', 'item', 'speaker', 'title']
	initialize(){
		this.subscription()
	}

	disconnect(){
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
		 if (this._subscription == undefined) {
      		let _this = this
      		this._subscription = consumer.subscriptions.create("ProgramEditorChannel", {
				connected() {},
				disconnected(){},
				received(data){
					if (data.cableReady) CableReady.perform(data.operations)
				}
			})
		}
		return this._subscription
	}

	connect() {
		this.sortSpeakers()
		this.sortProgramItems()
	  }

	sortProgramItems(){
		let _this = this
		if (this.hasItemTarget) {
		this.itemTargets.forEach(item => {
			item.setAttribute('style', 'z-index: 1000;')
		})
		const sortable = new Sortable(this.dayTargets, {
			draggable: 'tr',
			swapAnimation: {
			duration: 400,
			easingFunction: 'ease-in-out',
			vertical: true
			},
			plugins: [Plugins.SwapAnimation]
		})
		sortable.on('sortable:stop', function(event) {
			let array = Array.from(event.newContainer.children)
			array.forEach((item, index) => {
			if (item.classList.contains('draggable--original')) {
				array.splice(index, 1)
			} else if (item.classList.contains('draggable-mirror')) {
				array.splice(index, 1)
			}
			})
			let roomid = _this.dayTarget.dataset.id
			let positions = array.map(item => item.dataset.id)
			let new_order = event.newIndex
			let data = { positions:  positions, id: roomid, first_item: positions[0]  }
			_this.subscription().perform('update_item_positions', {data})
		
		})
		}
	}
	
	sortSpeakers(){
		let _this = this
		if (this.hasSpeakerTarget) {
		  this.speakerTargets.forEach(speaker => {
			speaker.setAttribute('style', 'z-index: 1000;')
		  })
		  const sortable = new Sortable(this.listTargets, {
			draggable: 'li',
			swapAnimation: {
			  duration: 400,
			  easingFunction: 'ease-in-out',
			  vertical: true
			},
			plugins: [Plugins.SwapAnimation]
		  })
		  sortable.on('sortable:stop', function(event) {
			let array = Array.from(event.newContainer.children)
			array.forEach((speaker, index) => {
			  if (speaker.classList.contains('draggable--original')) {
				array.splice(index, 1)
			  } else if (speaker.classList.contains('draggable-mirror')) {
				array.splice(index, 1)
			  }
			})
			let program_item_id = _this.listTarget.dataset.id
			let positions = array.map(speaker => speaker.dataset.id)
			let new_order = event.newIndex
			let data = { positions: positions , id: program_item_id  }
			_this.subscription().perform('update_speaker_positions', {data})
		  })
		}
	}
	submitTitle(e) {
		let url = e.srcElement.dataset.url
		let data = { program_speaker: { title: e.target.value } }

		fetch(url, {
			method: 'PUT',
			credentials: "same-origin",
  			headers: {
				"X-CSRF-Token": getMetaValue("csrf-token"),
				"Content-Type": "application/json",
      			"Accept": "application/json"
  			},
			body: JSON.stringify(data)
		  })
	}

}

function getMetaValue(name) {
	const element = document.head.querySelector(`meta[name="${name}"]`)
	return element.getAttribute("content")
  }
