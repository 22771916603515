import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [ "fileinput", "preview" ]
  connect() {}

  preview(e){
  	const preview = this.previewTarget
 	const file = e.target.files[0]
  	const reader = new FileReader();

	 reader.addEventListener("load", function () {
	    // convert image file to base64 string
	    preview.src = reader.result;
	  }, false);

	  if (file) {
	    reader.readAsDataURL(file);
	  }
  	}
  

}
