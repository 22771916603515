import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "form" ]

  connect() {
    
  }

  submitForm(e){
  	if(e.key === 'Enter') {
  		let form = this.formTarget
  		Rails.fire(form, 'submit');
  	}
  }


}
