import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"

export default class extends Controller {
  initialize() {
    this.subscription()
  }

  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }

  subscription() {
    let _this = this
    if (this._subscription == undefined) {

      this._subscription = consumer.subscriptions.create("UserChannel", {
        connected() {
          console.log('Connected to userchannel')
        },

        disconnected() { },

        received(data) {
          if (data.cableReady) CableReady.perform(data.operations)
        }
      }
      )
    }
    return this._subscription
  }

}




