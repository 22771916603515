import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
	static targets = ["room", "percent"]
	connect() {
		StimulusReflex.register(this)
	}
	initialize(){
		this.subscription()
	}

	disconnect(){
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}

	subscription() {
		let room = 'poll_backend_'+ this.roomTarget.dataset.room
		 if (this._subscription == undefined) {
      		let _this = this
      		this._subscription = consumer.subscriptions.create({ channel: "PollChannel", room: room}, {
				connected() {
					console.log('connected to PollChannel '+ room )
				},
				disconnected(){

				},
				received(data){
					if(data.msg === "poll_started")
						_this.pollStart(data)
					if(data.msg === "poll_ended")
						_this.pollStart(data)
					if(data.msg === "status_changed")
						_this.pollChangeStatus(data)
					else if(data.msg === "poll_new")
						_this.newAnswer(data)
				
				}
			})
		}
		return this._subscription
	}

	pollStart(data){
		console.log('pollStarted')
	}
	pollEnded(data){
		console.log('poll ended')
	}

	pollChangeStatus(data){
		let poll = document.getElementById('poll_'+data.id)
		let badge = document.getElementById('poll_icon_'+data.id)
		if (poll != null){
			if(data.poll_status === 'not_asked'){
				poll.classList.remove('text-green-500')
				poll.classList.add('text-gray-700')
				badge.classList.remove('fa-check')
				badge.classList.add('fa-poll')
			}else if(data.poll_status === 'in_progess'){
				poll.classList.remove('text-gray-700')
				poll.classList.add('text-yellow-500')
				badge.classList.remove('fa-poll')
				badge.classList.add('fa-spinner')
				badge.classList.add('fa-spin')
			}else {
				poll.classList.remove('text-yellow-500')
				poll.classList.add('text-green-500')
				badge.classList.remove('fa-spinner')
				badge.classList.remove('fa-spin')
				badge.classList.add('fa-check')
			}
		}		
	}

	newAnswer(data){
		console.log(data)
		let option = document.getElementById('option_'+data.option)
		option.innerHTML = data.answer_total
		let percent = document.getElementById('percent_'+data.option)
		percent.dataset.total = data.answer_total
		this.percentTargets.forEach((t)=> {
			let percent = ((parseInt(t.dataset.total)/data.total)*100).toFixed(2)
			t.innerHTML = percent
		})

	}


}
