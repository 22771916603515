import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['mail']

  connect() { }
  email(e) {
    e.preventDefault()
    let email = e.srcElement.dataset.name + "@meetando.no"
    let mailto_link = 'mailto:' + email
    this.mailTarget.setAttribute('href', mailto_link)
  }
}
