import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"

export default class extends Controller {
  static targets = ["room"]
  initialize() {
    this.subscription()
  }

  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }

  subscription() {
    let room = 'moderator_screen_' + this.element.dataset.room
    if (this._subscription == undefined) {
      let _this = this
      this._subscription = consumer.subscriptions.create({ channel: "ModeratorChannel", room: room }, {
        connected() {
          console.log('Connected to room  ' + room)
        },
        disconnected() {
        },
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations)
        }
      })
    }
    return this._subscription
  }
  dismiss(e) {
    this.subscription().perform('dismiss', { id: e.srcElement.dataset.id })
  }
}
