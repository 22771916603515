import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['time', 'title', 'output']

  connect() {
    console.log('connected countdown')
    let countDownDate = new Date(this.timeTarget.dataset.time).getTime();
    let _this = this
    // Update the count down every 1 second
    let x = setInterval(function () {

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      let html = ""
      if (days > 0) {
        html += ('<span class="text-3xl md:text-6xl">' + days + '</span>' + 'd ')
      }
      if ((days != 0 && hours > 0) || hours > 0) {
        html += ('<span class="text-3xl md:text-6xl">' + hours + '</span>' + 'h ')
      }
      html += ('<span class="text-3xl md:text-6xl">' + minutes + '</span>' + 'm ')
      html += ('<span class="text-3xl md:text-6xl">' + seconds + '</span>' + 's ')

      // Display the result in the element with id="demo"
      _this.outputTarget.innerHTML = html;

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        _this.titleTarget.innerHTML = "<a href='" + _this.titleTarget.dataset.url + "' class='btn_page_big_cta w-full '>" + _this.titleTarget.dataset.live + "</a>";
      }
    }, 1000);
  }
}