import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "provider" , "embed", "code"]

  connect() {
  }

  checkValue(){
  	console.log(this.providerTarget.value)
  	switch(this.providerTarget.value){
  		
  		case 'wowza':
  			this.showCode()
  			break;
		case 'vimeo':
  		case 'youtube':
  		case 'twitch':
  			this.showEmbed()
  			break;
  		default:
  			this.showEmbed()
  	}
  }

  showCode(){
  	this.codeTarget.classList.remove('hidden')
  	this.embedTarget.classList.add('hidden')
  }

  showEmbed(){
  	this.codeTarget.classList.add('hidden')
  	this.embedTarget.classList.remove('hidden')
  }
}
