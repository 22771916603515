import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = ["text", "yes", "no", "form"]

  connect() {
  }


  confirmSubmit(e) {
    e.preventDefault()
    Swal.fire({
      title: e.srcElement.dataset.title,
      text: e.srcElement.dataset.text,
      icon: "question",
      confirmButtonText: e.srcElement.dataset.yes,
      cancelButtonText: e.srcElement.dataset.no,
      showConfirmButton: true,
      showCancelButton: true,
    }).then(function (isConfirm) {
      if (isConfirm.value == true) {
        let form = e.srcElement.form
        Rails.fire(form, 'submit');
      }
    });
  }
}
