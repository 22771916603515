import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["button", "one", "two", "three", "four", "five", "six", "hidden", "form"]

  connect() {
    let target = this.oneTarget
  }

  typingCode(e) {
    console.log(e.srcElement.dataset.order)
    console.log(e)
    var inp = String.fromCharCode(e.keyCode);
    if (/[a-zA-Z0-9-_ ]/.test(inp)) {
      this.setTarget(e.srcElement.dataset.order)
    } else if (e.key === 'Backspace') {
      this.goBack(e.srcElement.dataset.order)
    } else if (e.key === 'ArrowLeft') {
      this.goBack(e.srcElement.dataset.order)
    } else if (e.key === 'ArrowRight') {
      this.setTarget(e.srcElement.dataset.order)
    }
    else if (e.key === 'Enter') {
      this.buttonTarget.click()
    }
  }

  setTarget(order) {
    switch (order) {
      case '1':
        this.twoTarget.focus()
        break;
      case '2':
        this.threeTarget.focus()
        break;
      case '3':
        this.fourTarget.focus()
        break;
      case '4':
        this.fiveTarget.focus()
        break;
      case '5':
        this.sixTarget.focus()
        break;
      case '6':
        this.getCode()
        break;
    }
  }

  goBack(order) {
    switch (order) {
      case '2':
        this.oneTarget.focus()
        break;
      case '3':
        this.twoTarget.focus()
        break;
      case '4':
        this.threeTarget.focus()
        break;
      case '5':
        this.fourTarget.focus()
        break;
      case '6':
        this.fiveTarget.focus()
        break;
    }
  }

  getCode() {
    let code = ''
    code += this.oneTarget.value
    code += this.twoTarget.value
    code += this.threeTarget.value
    code += this.fourTarget.value
    code += this.fiveTarget.value
    code += this.sixTarget.value
    this.hiddenTarget.value = code
  }

  pasting(e) {
    e.preventDefault()
    console.log('PASTING')
    console.log(e)

    console.log((e.clipboardData || window.clipboardData).getData('text'))
    let code = (e.clipboardData || window.clipboardData).getData('text')
    this.oneTarget.value = code[0]
    this.twoTarget.value = code[1]
    this.threeTarget.value = code[2]
    this.fourTarget.value = code[3]
    this.fiveTarget.value = code[4]
    this.sixTarget.value = code[5]
    this.sixTarget.focus()
    this.hiddenTarget.value = code
  }
}
