import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "register", "login"]

  connect() {
  	console.log("Hello from eventregister controller")
  }

  displayRegister(){
  	this.registerTarget.classList.remove('hidden')
  	this.loginTarget.classList.add('hidden')
  }

  displayLogin(){
  	this.registerTarget.classList.add('hidden')
  	this.loginTarget.classList.remove('hidden')
  }
}
