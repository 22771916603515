import { Controller } from "stimulus"
import consumer from '../../channels/consumer'

export default class extends Controller {
	static targets = ["polls"]

	connect() {
		//console.log('Hello poll controller')
	}
	initialize(){
		this.subscription()
	}

	disconnect(){
		this.subscription().unsubscribe()
		this.subscription().disconnected()
	}


	subscription() {
		let room = "polls_"+this.pollsTarget.dataset.room
		 if (this._subscription == undefined) {
      		let _this = this
      		this._subscription = consumer.subscriptions.create({ channel: "PollChannel", room_slug: room}, {
				connected() {
					//console.log('poll is connected to ' + room)
				},
				disconnected(){

				},
				received(data){
					//console.log('Inncoming for poll controller')
					if(data.msg === "start")
						_this.enterPoll(data.poll);
					else if(data.msg === "stop")
						_this.removePoll(data.id);
					else if(data.msg === "show_poll")
						_this.showResultPoll(data.poll);
					else if(data.msg === "hide_poll")
						_this.hideResultPoll(data.id);
				}
			})
		}
		return this._subscription
	}

	enterPoll(poll){
		this.pollsTarget.insertAdjacentHTML('afterbegin', poll)
	}

	showResultPoll(poll){
		this.pollsTarget.insertAdjacentHTML('afterbegin', poll)
	}

	hideResultPoll(id){
		let poll = document.getElementById('poll_result_'+id)
		if (poll != null) {
			poll.classList.add('fadeOut')
				setTimeout(function(){
					poll.parentNode.removeChild(poll)
					}, 1000)
		}
	}
	removePoll(id){
		let poll = document.getElementById('poll_'+id)
		if (poll != null) {
			poll.classList.add('fadeOut')
				setTimeout(function(){
					poll.parentNode.removeChild(poll)
					}, 1000)
		}
		
		
	}
}
