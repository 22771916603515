import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu", "dropdown" ]

  connect() {
      console.log('Im here')
  }

  toggleMenu(){
    this.dropdownTarget.dataset.isopen === 'false' ? this.openMenu() : this.closeMenu()
      console.log('Action')
  }

  openMenu(){
    this.dropdownTarget.classList.remove('hidden')
    this.dropdownTarget.dataset.isopen = 'true'
  }

  closeMenu(){
    this.dropdownTarget.classList.add('hidden')
    this.dropdownTarget.dataset.isopen = 'false'
  }
}